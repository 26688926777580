export default [
  {
    label: 'Created At',
    field: 'createdAt',
  },
  {
    label: 'Type',
    field: 'type',
  },
  {
    label: 'Input',
    field: 'inputData',
  },
  {
    label: 'Output',
    field: 'outputData',
  },
]

export const biometricDocuments = [
  {
    label: 'kyc.dashboard.country',
    field: 'country',
  },
  {
    label: 'kyc.dashboard.document',
    field: 'document',
  },
  {
    label: 'kyc.dashboard.totalRequest',
    field: 'totalRequest',
  },
  {
    label: 'kyc.dashboard.monitorPercent',
    field: 'monitorPercent',
  },
]

export const biometricF2F = [
  {
    label: 'kyc.dashboard.country',
    field: 'country',
  },
  {
    label: 'kyc.dashboard.totalPercent',
    field: 'totalPercent',
  },
  {
    label: 'kyc.dashboard.failedPercent',
    field: 'failedPercent',
  },
  {
    label: 'kyc.dashboard.approvedPercent',
    field: 'approvedPercent',
  },
]
